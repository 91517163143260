@font-face {
  font-family: "SallenasGrande Regular";
  src: url(./../fonts/SallenasGrandesRegular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: "Montserrat Regular";
  src: url(./../fonts/MontserratRegular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: "Montserrat Light";
  src: url(./../fonts/MontserratLight.ttf);
  font-weight: 400;
}
@font-face {
  font-family: "Montserrat Bold";
  src: url(./../fonts/MontserratBold.ttf);
  font-weight: 400;
}

$colors: (
  headColor: #caa987,
  headColorLight: #e4be98,
  welcomeHead: #5c534c,
  elementText: #524033,
  borderAbout: rgb(83, 85, 0),
  whiteCreamy: #fcebda,
  whiteCreamyHover: #f3efea,
  navBarBackground: rgb(0 124 32 / 80%),
  navBarBackgroundMobile: rgba(116, 51, 51, 0),
  burgerColorBackground: #26895b,
  liItemColor: #d9ff00,
  whitePure: rgb(255, 255, 255),
  whiteSmokey: whitesmoke,
  chillGrayTransparent: rgba(95, 95, 95, 0.41),
  chillGraySolid: rgb(95, 95, 95),
  blueSky: rgb(4, 126, 182),
  redBomb: rgb(252, 29, 29),
  silver: rgb(169, 169, 169),
  beautyBush: #eeb9cb,
  blackSolid: black,
  errorRed: #ff0000
);
