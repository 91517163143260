.longBanner {
  width: 100%;
  height: 22px;
  background-color: red;
  position: fixed;
  margin-top: 105px;

  animation: fadeIn ease 10s;
  -webkit-animation: fadeIn ease 10s;
  -moz-animation: fadeIn ease 10s;
  -o-animation: fadeIn ease 10s;
  -ms-animation: fadeIn ease 10s;
  opacity: 0;
}
a {
  text-decoration: none !important;
}
.texter {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
}

@keyframes fadeIn {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
